// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }
 
 // Medium devices
 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
 }

 @mixin smOnly  {
    @media (max-width: #{$screen-md-min - 1} ) {
        @content;
    }
 }
// MyDNA old styling
 $control-width: 20rem;
 $link-color: #000;
 $mydna-blue: #30b5e8;
 $gray: #a09e9e;
 $scroll-bg: #313131;
// end old styling

 $accentColor: #a3f89b;
 $primaryText:  #ffffff; 
 $inversePrimaryText:  #000000;
 
