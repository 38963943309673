@import '../../Styles/variables.scss';

//important to override mui theme

.card_container {
    background-color: #000000;
    color: $primaryText;
    
    border: 1px solid #ffffff;
    width: 372px;
    margin: 0px auto;
    margin-bottom: 36px;
}


.card_content {
    background-color: #000000;
    color: $primaryText;
    padding-top: 24px;
    padding-bottom: 12px;
    padding-left: 36px;
    padding-right: 36px;
    min-height: 350px;
}

.card_recommended {
    background-color: #ffffff !important;
    color: $inversePrimaryText !important;
}


.card_title {
    font-weight: 600 !important; 
    font-size: 16px !important;
    color: $primaryText;
    text-align: left;
    margin-bottom: 36px !important;
}

.card_price {
    font-weight: 800 !important;
    padding-bottom: 10px;
    font-size: 1.4rem !important;
}

.card_recommendedBox
{
    background-color: $accentColor;
    width: 220px;
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p 
    {
        font-weight: 800 !important;
        color: $inversePrimaryText !important;
    }
}