@import '../../Styles/variables.scss';

.healthReport__suggestionitem {
    
    font-size: 22px !important;
    line-height: 1.25 !important;

}

.healthReport__suggestionitem__wrappedtext
{
    font-size: 22px !important;
    line-height: 1.25 !important;
    margin-left: 4px !important;
}

.healthReport__item {
    height: 110px;
    .item1 {
       
        @include lg {
            text-align: left;
            margin-left: 90px;
        }
    }
    .item2 {
        @include lg {
            text-align: left;
            margin-left: 30px;
        }
    }
    .item3 {
       
        @include lg {
            text-align: left;
            margin-left: 50px;
        }
    }
    .item4 {
       
        @include lg {
            text-align: left;
            margin-left: 80px;
        }
    }
}