@import '../../Styles/variables.scss';

.textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #000;
    color: #fff;
    margin: auto;
    padding: 10px;
    width: 100% !important;
    max-width: 960px;
    font-family: 'Moderat';
    font-weight: 400;
    font-style: normal;
    font-size: 16px !important;
    line-height: 150%;
    letter-spacing: 0.03em;
}

.upform__questions {
    color: #fff;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
    font-family: 'Moderat';
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    letter-spacing: 0.03em;
}

.form__control {
    color: #fff;
    text-align: center;
    width: 100%;
    font-family: 'Moderat';
    font-weight: 400;
    min-height: 200px;
}

.upform__rbwrapper {
    display: flex;    
    flex-direction: column !important;

    @include md {
        flex-direction: row !important;
    }
}

.upform__rbwrapper {
    display: flex;    
    flex-direction: column !important;

    @include md {
        flex-direction: row !important;
    }
}

.upform__rbitem {

    word-break: break-word;
    @include smOnly {
        width: 300px !important;
        text-align: left;
    }
}
