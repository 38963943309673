@import '../Styles/variables.scss';
@mixin styledButton-rounded {
    gap: 10px;
    height: 50px;
    display: flex;
    width: 90%;
    font-style: normal;
    font-family: Moderat;
    font-weight: 600!important;
    line-height: 100%;
    border-radius: 0;
    letter-spacing: 0.3em;
    border-radius: 3px;
    font-size: 14px!important;
    letter-spacing: 0.1em;
    @include md {
        width: 200px;
    }
    span: {
        font-family: inherit;
    }
    margin-right: 16px;
    margin-left: 16px;
}
.styledButton__rounded {
    @include styledButton-rounded;
    color: #000000 !important;
    border: 1px solid #FFFDF9!important;   
    background-color: #FFFDF9!important;
    &:hover {
        background: #FFFDF9!important;
    }    
}
.styledButton__roundedInv {
    @include styledButton-rounded;
    color: #FFFDF9!important;
    border: 1px solid #FFFDF9!important;
    background-color: #000000!important;
    span: {
        font-family: inherit;
    }
    &:hover {
        background: #000000;
    }
}
.styledButton__roundedInvPlain {
    @include styledButton-rounded;
    color: #FFFDF9!important;
    border: 0px none !important;
    background-color: #000000!important;
    span: {
        font-family: inherit;
    }
    &:hover {
        background: #000000;
    }
    @include md {
        width: 300px;
    }
}